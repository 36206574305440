"use strict";

function Tabs(args) {
  // Scope-safe constructors
  if (!(this instanceof Tabs)) { 
    return new Tabs();
  }
  
  // Default component settings 
  let defaults = {
    container: '[data-megamenu]',
    trigger: '.trigger[role="tab"]',
    content: '.content[role="tabpanel"]'
  };
  
  // If there are no settings overrides
  let settings = (typeof args !== 'undefined') ? args : defaults;

  // Handles the displaying/hiding of content
  let toggle = function() {
    let parent = this.closest(settings.container),
        target = this.getAttribute('aria-controls'),
        trigger = this,
        content = document.getElementById(target),
        toggles = parent.querySelectorAll(settings.trigger),
        all_content = parent.querySelectorAll(settings.content);

    // Update visibility
    for (let i = 0, len = toggles.length; i < len; i++) {
      toggles[i].setAttribute('aria-selected', 'false');
      all_content[i].setAttribute('aria-hidden', 'true');
    }
    
    this.setAttribute('aria-selected', 'true');
    content.setAttribute('aria-hidden', 'false');
  };

  // Attach event listeners
  let bindEventListeners = function() {
    let trigger = document.querySelectorAll(settings.trigger);
    
    for (let i = 0, len = trigger.length; i < len; i++) {
      trigger[i].addEventListener('mouseover', function(event) {
        // alert('hover');
        toggle.call(this);
      });
    };
  };

  return bindEventListeners();
}

// Create an instance of component
window.onload = function() {
  let tabs = new Tabs();
};

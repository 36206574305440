    let autohide = document.querySelector('.autohide');

    // add padding-top to body (if necessary)
    // navbar_height = document.querySelector('.navbar').offsetHeight;
    // document.body.style.paddingTop = navbar_height + 'px';

    if (autohide) {
        var last_pos = 0;
        window.addEventListener('scroll', function () {
            let current_pos = window.scrollY;
            if (current_pos < last_pos) {
                autohide.classList.remove('scrolled-down');
                autohide.classList.add('scrolled-up');
            }
            else {
                autohide.classList.remove('scrolled-up');
                autohide.classList.add('scrolled-down');
            }
            last_pos = current_pos;
        });
    }